import React, { useState, useRef, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'
import moment from 'moment';
import InfiniteScroll from "react-infinite-scroll-component";
import { DateSvg, EventLocationSvg, } from '../../assets/svg'
import { NO_EVENTS } from '../../assets/images'
import EventCardLoader from '../Event-card-loader/EventCardLoader'
import { height } from '@mui/system';
import axiosInstance from '../../api/api';

const EventsCard = () => {
    const containerRef = useRef();
    const navigate = useNavigate();
    const [hasMore, setHasMore] = useState(true);
    const limit = 3; // Set your limit here
    const [offset, setOffset] = useState(1);
    const [items, setItems] = useState([]);
    const [thresholdValue, setThresholdValue] = useState("1300px")
    const handleView = (id) => {
        navigate(`/events/events-detail/${id}`);
    };

    const imageRef = useRef(null);
    const [width, setWidth] = useState();
  
    const updateWidth = () => {
      if (imageRef.current) {
        setWidth(imageRef.current.offsetWidth);
      }
    };
    const fetchData = async () => {
        try {
          const res = await axiosInstance.get(
            `/events/web/list?limit=${limit}&offset=${offset}`
          );
          if (res.data.status === false) {
            setHasMore(false);
          }
          if (!res?.data?.status) {
            setHasMore(false);
            return;
          } else {
            const newItems = res?.data?.data;
            setItems((prevItems) => {
                const itemIds = prevItems.map(item => item.id);
                const filteredNewItems = newItems.filter(item => !itemIds.includes(item.id));
                return [...prevItems, ...filteredNewItems];
            });
            setOffset(offset + 1);
          }
        } catch (e) {
          setHasMore(false);
        }
      };
    useEffect(() => {
        fetchData();
        updateWidth();
        if(window.innerWidth < 540){
            setThresholdValue("2500px")
          }
        window.addEventListener('resize', updateWidth);
        return () => {
          window.removeEventListener('resize', updateWidth);
        };
       
      }, []);

    return (
        <div>
            <Container>
                <div className='featured-events'>
                    {items.length > 0 ?
                        (<h1 className='text-center mb-5' >Featured Events</h1>) : (null)
                    }
                    <InfiniteScroll
                        dataLength={items?.length}
                        next={fetchData}
                        hasMore={hasMore}
                        scrollThreshold ={thresholdValue}
                        loader={
                            <EventCardLoader />
                        }
                        ref={containerRef}
                    >
                        <Row>
                            {
                                items?.length > 0 ? (
                                    items?.map((item, index) => (
                                        
                                        <Col lg={4} md={6} key={index} className='job_column' >
                                            <div className='event_card' ref={imageRef} onClick={() => handleView(item?.id)}>
                                                
                                                <img className='event-img' style={{height: width*0.67}} src={item?.thumbnailImageUrl} alt={item?.eventTitle} />
                                                <div className='event_cont'>
                                                    <h3 className='event_title'>{item?.eventTitle}</h3>
                                                    <div className='event_keys'>
                                                        <div className='event_keys_cont'>
                                                            <DateSvg />
                                                            <p>{moment(item?.eventDate).format('dddd, DD MMMM, YYYY')}</p>
                                                        </div>
                                                        <div className='event_keys_cont'>
                                                            <EventLocationSvg />
                                                            <p>{item?.eventLocation}</p>
                                                        </div>
                                                    </div>
                                                    <p className='event_description'>
                                                        <div dangerouslySetInnerHTML={{ __html: item?.eventDescription }} />
                                                    </p>
                                                    <div className='action_button'>
                                                        <Link className="explore-event" to="">
                                                            Explore Event
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    ))) : (
                                    <Col>
                                        <div className='no_data_message'>
                                            <img src={NO_EVENTS} alt="no events image" />
                                            <p className='mt-4'>Currently no events in <br /> Nimalaan</p>
                                        </div>
                                    </Col>
                                )
                            }
                        </Row>
                    </InfiniteScroll>
                </div>
            </Container>
        </div>
    )
}

export default React.memo(EventsCard)